<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：公司资料
开始时间：2021-4-7
开发人员：姚雨宏
最后修改：2021-4-7
备注说明：如需修改请联系开发人员 -->

<template>
  <div class="company_back">
    <div class="company_profile">
      <div class="content">
        <transition mode="out-in" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave"
          :css="false">
        </transition>
        <el-form label-width="150px" v-model="inputData" style="width: 40%;">

          <el-form-item label="企业logo:" style="width: 100%;">
            <div class="head_portrait">
              <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="imgUrl" :src="imgUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>

            </div>

          </el-form-item>
          <el-form-item label="企业名称:" style="width: 100%;">
            <el-input v-model="inputData.company_name">k
            </el-input>
          </el-form-item>
          <el-form-item label="企业域名:" style="width: 100%;">
            <el-input v-model="inputData.domain" :disabled="true">
            </el-input>
          </el-form-item>
          <el-form-item label="企业简称:" style="width: 100%;">
            <el-input v-model="inputData.simple_name"></el-input>
          </el-form-item>
          <el-form-item label="所属行业:" style="width: 100%;">
            <el-select v-model="inputData.company_calling" placeholder="请选择行业" style="width: 100%;">
              <el-option label="手机、通讯" value="100000509"></el-option>
              <el-option label="消费电子" value="100000044"></el-option>
              <el-option label="电脑及配件" value="100000007"></el-option>
              <el-option label="汽车、摩托车及零件" value="100000034"></el-option>
              <el-option label="服装服饰" value="100000003"></el-option>
              <el-option label="鞋、袜、帽及配件" value="100000322"></el-option>
              <el-option label="箱包" value="100001524"></el-option>
              <el-option label="钟表、珠宝首饰、眼镜" value="100000036"></el-option>
              <el-option label="运动及娱乐" value="100000018"></el-option>
              <el-option label="玩具" value="100000026"></el-option>
              <el-option label="礼品、工艺品" value="100000017"></el-option>
              <el-option label="健康美容、个人护理" value="100000066"></el-option>
              <el-option label="照明、灯具" value="100000039"></el-option>
              <el-option label="家居园艺" value="100000015"></el-option>
              <el-option label="家具及室内装饰品" value="100001503"></el-option>
              <el-option label="办公用品及文具" value="100000021"></el-option>
              <el-option label="包装和纸" value="100000023"></el-option>
              <el-option label="印刷和出版" value="100000035"></el-option>
              <el-option label="安全产品" value="100000030"></el-option>
              <el-option label="汽车配件" value="100000034"></el-option>
              <el-option label="五金工具" value="100001420"></el-option>
              <el-option label="纺织" value="100000004"></el-option>
              <el-option label="家用电器" value="100000006"></el-option>
              <el-option label="食品及饮料" value="100000002"></el-option>
              <el-option label="新能源" value="100000029"></el-option>
              <el-option label="摸具" value="100000027"></el-option>
              <el-option label="其他" value="100000028"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属地区:" style="width: 100%;">
            <el-input v-model="inputData.country" style="width: 33%;" placeholder="国家"></el-input>
            <el-input v-model="inputData.province" style="width: 33%;" placeholder="省份"></el-input>
            <el-input v-model="inputData.city" style="width: 33%;" placeholder="市区"></el-input>
          </el-form-item>
          <el-form-item prop="dh" label="企业电话:" style="width: 100%;">
            <el-input type="dh" v-model="inputData.telephone" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>

          <el-form-item label="企业网址:" style="width: 100%;">
            <el-input v-model="inputData.website">
              <span style="color: red;">如果使用SEO推广，务必配置</span>
            </el-input>
          </el-form-item>

          <el-form-item label="联系地址:" style="width: 100%;">
            <el-input v-model="inputData.address"></el-input>
          </el-form-item>
          <el-form-item label="企业简介:" style="width: 100%;">
            <el-input type="textarea" resize="none" :rows="3" v-model="inputData.profile" maxlength="80"
              show-word-limit></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" @click="updateCompany('inputData')">保存修改</el-button>
    </div>
  </div>
</template>

<script>
  import api from '@/network/datum/companyInfo'
  export default {
    data() {
      return {
        imgUrl: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=3294319617,1938412284&fm=26&gp=0.jpg',
        inputData: {
          company_name: '',
          domain: '',
          simple_name: '',
          country: '',
          province: '',
          telephone: '',
          website: '',
          address: '',
          city: '',
          company_calling: '',
          profile: '',
        },
      }
    },
    created() {

      this.findCompany();
    },
    methods: {
      findCompany() {
        api.findCompanyInfo().then((res) => {
          if (res != null) {
            this.inputData = res.data;
          }
          console.log(res);
        })
      },
      updateCompany(inputData) {
        api.updateCompanyInfo(this.inputData).then(res => {
          if (res.data == 1) {
            this.$message({
              message: "修改成功",
              type: 'success',
              duration: '1500'
            })
          } else {
            this.$message({
              message: "修改失敗",
              type: 'error',
              duration: '1500'
            })
          }
          this.findCompany();

        })
      },
    }
  }
</script>

<style scoped="scoped" lang="less">
  /* 背景色*/
  .company_back {
    width: 100%;
    height: 100%;
    // background-color: @form_bgColer;
  }

  /* 盒子居中 */
  .company_profile {

    width: 1500px;
    height: 700px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 0px @borderColor;
    text-align: center;

    .content {
      display: flex;

      /* 头像样式*/
      .head_portrait {

        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409EFF;
        }

        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
        }

        .avatar {
          width: 130px;
          height: 130px;
          margin-left: 0px;
          margin-top: 1px;
          display: block;
        }
      }
    }




  }
</style>
